<template>
  <div class="music-detail-container">
    <div class="crumb-wapper">
      <vf-breadcrumb :data="breadData"></vf-breadcrumb>
    </div>
    <div class="content"  v-loading="loading">
      <div class="l-wrap">
        <div class="music-info">
          <div class="flex">
            <div class="header-img" @click="handlePlayerMusic({ ...musicData, type: 2, labels: musicData.label })">
              <img :src="musicData.logo" alt="" class="img">
              <div class="audio-status">
                <img v-if="playing && musicData.music_id == tmpMusicInfo.music_id" src="~@/assets/svg/operation/play_icon.svg"/>
                <img v-else src="~@/assets/svg/operation/pause_icon.svg"/>
              </div>
            </div>
            <div class="info">
              <h3>{{musicData.name}}</h3>
              <h4 v-if="musicData.label && musicData.label.length">{{ musicData.label.map(i => i.name).join('，')}}</h4>
              <span @click="dialogVisibleAuth=true">如何获取授权证书？</span>
            </div>
          </div>
          <div class="operation">
            <div><img :src="require(`@/assets/svg/${musicData.is_collect === 0 ? 'collect' : 'collected'}.svg`)" @click="handleMusicCollect(musicData)" /></div>
            <div><img :src="require('@/assets/svg/share-gray.svg')" @click="handleShare('', musicData.object_id, 2, musicData.name)" /></div>
          </div>

        </div>
        <div class="package-info">
          <div class="package-bg" v-show="packageType == 1">
            <img src="~@/assets/image/music/person-select-bg.png" alt="">
          </div>
          <div class="package-bg" v-show="packageType == 2">
            <img src="~@/assets/image/music/company-select-bg.png" alt="">
          </div>
          <div class="goods-tab" :class="{ tabCompany: packageType == 1 }">
            <div class="person-tab" :class="{ 'person-active': packageType == 1 }" @mouseenter="choosePackage(1)">
              <strong>个人会员</strong><span>适用于个人非商用项目</span>
            </div>
            <div class="company-tab" :class="{ 'company-active': packageType == 2 }" @mouseenter="choosePackage(2)">
              <strong>企业会员</strong><p>商用首选</p><span>适用于企业项目</span>
            </div>
          </div>
          <div class="goods-wrap">
            <div
              v-for="(item,index) in packageList"
              :key="item.goods_id"
              @mouseenter="handleCheckGoods(index)"
              @mouseleave="handleCheckGoods('')"
            >
              <div :class="[packageType == 1 ? 'person-goods-item' : 'company-goods-item', chooseIndex == index ? 'choose' : '',]">
                <div class="title">{{ item.name }}</div>
                <!-- is_sum 企业会员包月年付商品 -->
                <div class="price" v-if="item.is_sum == 3"><i>￥</i>{{ Number(item.per_price) }}</div>
                <div class="price" v-else><i>￥</i>{{ Number(item.price) }}</div>
                <div class="desc">
                  <div>
                    <span>下载次数</span>
                    <span class="num">{{ item.down_count == 'n' ? '不限' : item.down_count + '次' }}</span>
                  </div>
                  <div>
                    <span>单日下载限制</span>
                    <span class="num">{{ item.down_num }}次</span>
                  </div>
                  <div v-if="item.type == 2">
                    <span>可授权企业数</span>
                    <span class="num">{{ item.cert_num }}家</span>
                  </div>
                </div>
                <div class="pay">
                  <p v-if="item.is_month==1 || item.is_sum == 3" id="lijikaitong" data-agl-cvt="7" @click="handleMonthPay(item)">立即订阅</p>
                  <p v-else id="lijikaitong" data-agl-cvt="7" @click="immediatePayment( item, true)">
                    立即订阅
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
      <div class="scene-info">
        <div class="scene-wrap">
          <div class="img-icon">
            <img src="~@/assets/image/music/scene_title_icon.png" alt="">
            <span>或许您也可以选择</span>
          </div>
          <div class="title">- 单首购买 -</div>
          <div
            v-for="(item,index) in sceneList"
            :key="index" class="scene-items"
            :class="{'scene-active': chooseSceneList.includes(item.sk_id)}"
            @click="handleChooseScene(item)"
          >
            <div class="name">
              <div :class="{'active': chooseSceneList.includes(item.sk_id)}"><span>{{item.name}}</span>
              <el-tooltip placement="top">
                <div slot="content" class="tooltip-c">
                  {{item.desc}}
                </div>
                <img class="qus" src="~@/assets/svg/question-icon.svg" alt="">
              </el-tooltip></div>
            </div>
            <div class="price">￥{{parseInt(item.price / 100)}}</div>
          </div>
          <div class="tip"><i class="el-icon-warning-outline" style="color: #FF8E14"></i><p>所有授权场景均支持随片永久，投放全球，不得进行转授权</p></div>
        </div>
        <div class="pay-info" :class="{'pay-active': chooseSceneList.length}">
          <div class="price">应付金额：<span>{{chooseSceneList.length ? `￥${Number(totalPrice / 100)}` : '-'}}</span></div>
          <el-button class="retail-pay" :loading="payLoading" @click="handlePay">立即支付</el-button>
        </div>
      </div>
    </div>
    <section class="recommend" v-if="similarList.length">
      <p class="title">相似音乐推荐</p>
      <music-list v-loading="similarLoading" :list="similarList" :isSimilar="false" :isMidia="false"></music-list>
    </section>
    <!-- 优惠支付 -->
    <LadderToPay ref="ladderPayRef" @ladderPay="topay"></LadderToPay>

    <!-- 连续包月支付 -->
    <DialogForMonthPay ref="aliMonthPayRef" :alipayCode="aliMonthPayCode" @close="handleMontyPayClose()" @refresh="initMonthOrderSign(payGoodInfo)"></DialogForMonthPay>
    <!-- 致用户的一封信 -->
    <Letter :showLetter="showLetter" v-show="showLetter" @closeLetterDialog="closeLetter" />
    <DialogForPay
      :dialogVisible="dialogVisiblemember"
      :isRetail="isRetail"
      :payGoodInfo="payGoodInfo"
      :wxPayQrCode="wxPayQrCode"
      :aliPayQrCode="aliPayQrCode"
      @close="handlePayClose"
      @publicSubmit="handlePublicSubmit"
    ></DialogForPay>
    <DialogFonrHowAuth :dialogVisible="dialogVisibleAuth" @close="dialogVisibleAuth=false"></DialogFonrHowAuth>

  </div>
</template>

<script>
import Buttons from '@/layout/mixins/Buttons'
import { fetchMusicDetail, addMusicOrder, fetchMusicPayCode, fetchPayCodeResult } from '@/api/music'
import {
  fetchOrderPayByAliPay,
  fetchPackageList,
  fetchOrderPayByWxPay,
  initMonthOrderSign,
  fetchMonthOrderStatus,
  fetchOrderPayStatusByPackage
} from '@/api/package'
import Letter from '@/views/package/components/Letter'
import DialogForPay from '@/components/DialogForPay'
import LadderToPay from '@/views/package/components/LadderToPay.vue'
import DialogForMonthPay from '@/views/package/components/DialogForMonthPay.vue'
import DialogFonrHowAuth from '../components/DialogForHowAuth.vue'
import { fetchMusicSimilar } from "@/api/music";
import { payReloadTime } from '@/config'
import { formDateTime } from '@/utils/formDate.js'
import { send_eventApi } from '@/utils/common.js'
import MusicList from '../components/MusicList.vue';
import { getToken } from "@/utils/author";
import VfBreadcrumb from '@/components/VfBreadcrumb.vue'

export default {
  name: 'MusicDetail',
  components: {
    MusicList,
    Letter,
    LadderToPay,
    DialogForMonthPay,
    DialogForPay,
    VfBreadcrumb,
    DialogFonrHowAuth
  },
  data() {
    return {
      breadData: [
        {breadcrumbName: 'PlayList', path: '/'},
        {breadcrumbName: '音乐', path: '/music'},
        {breadcrumbName: 'name'}
      ],
      payLoading: false,
      loading: false,
      similarLoading: false,
      totalPrice: 0,
      similarList: [],
      chooseSceneList: [],
      payGoodInfo: {},
      packages: {},
      packageType: 2, // 1:个人 2:企业
      chooseIndex: '',
      showLetter: false,
      dialogVisiblemember: false,
      dialogVisibleAuth: false,
      wxPayQrCode: '',
      aliPayQrCode: '',
      aliMonthPayCode: '', // 支付宝连续包月二维码

      // 定时器
      wxPayTimer: null,
      aliPayOrderTimer: null,
      retailTimer: null,

      isRetail: false,

      sceneList:[],
      musicData: {},
    }
  },
  mixins: [Buttons],
  computed: {
    packageList() {
      return this.packages[this.packageType] ? this.packages[this.packageType].filter((i) => i.is_able == 1) : []
    },
    tmpMusicInfo() {
      return this.$store.state.music.tmpMusicInfo
    },
    playing() {
      return !!this.$store.state.music.isPlaying
    }
  },
  watch: {
    '$route.params.id': {
      handler() {
        // this.getPackageList()
        this.getDetail()
        this.getSimilarMusicList()
      }
    }
  },
  mounted() {
    this.getDetail()
    this.getPackageList()
    // this.backUrl = `${routeQuery.backUrl}&currentTag=${routeQuery.currentTag || ''}&order=${routeQuery.order || ''}&pattern=${routeQuery.pattern || ''}`
    this.getSimilarMusicList()
  },
  methods: {
    // 获取相似音乐列表
    async getSimilarMusicList(){
      try {
        this.similarLoading = true
          const { data } = await fetchMusicSimilar(this.$route.params.id);
          this.similarLoading = false
          this.similarList = data
        } catch (e) {
          this.similarLoading = false
          console.log(e);
        }
    },
    async handleMusicCollect(item) {
      if (this.tmpMusicInfo && this.tmpMusicInfo.object_id) {
        if (item.object_id === this.tmpMusicInfo.object_id) {
          this.$message.warning('正在播放的音乐请在播放器中操作')
          return
        }
      }
      if (!(getToken() && getToken() !== '')) {
        this.$store.dispatch('user/openLogin')
        return
      }
      item.is_collect = item.is_collect === 0 ? 1 : 0
      await this.handleCollect(item.object_id, 2, {
        ...item,
        type: 2,
        labels: item.label
      }, false)
    },
    handleCheckGoods(index) {
      this.chooseIndex = index
    },
    choosePackage(val){
      this.packageType = val
    },
    handleChooseScene(scene){
      let index = this.chooseSceneList.indexOf(scene.sk_id);
      if(index === -1) {
        this.chooseSceneList.push(scene.sk_id)
        this.totalPrice += scene.price
      }
      else {
        this.chooseSceneList.splice(index, 1)
        this.totalPrice -= scene.price
      }
    },
    getPackageList() {
      fetchPackageList().then((res) => {
        const { data } = res
        this.packages = data
      })
    },
    goPackage(){
      this.$router.push('/package?type=2')
    },
    // 进阶支付
    topay(e) {
      const {type, relGood, orderId } = e
      this.payGoodInfo = relGood
      if (type == 1) {
        //原套餐
        this.ladderTopay = false
        this.$refs.ladderPayRef.handleClosecreator()
        this.dialogVisiblemember = true
        if (orderId) {
          this.getPayQrCodeUpgrade(relGood.goods_id)
        } else {
          this.getPayQrCode(relGood.goods_id)
        }
      } else {
        //进阶套餐
        this.$refs.ladderPayRef.handleClosecreator()
        this.dialogVisiblemember = true
        if (orderId) {
          this.getPayQrCodeUpgrade(relGood.goods_id)
        } else {
          this.getPayQrCode(relGood.goods_id)
        }
      }
    },
    // 处理 个人连续包月或企业年付 只支付一次
    handleMonthPay(info){
      if(info.is_contract == 1) return this.$message.warning('您已购买该商品，不可重复购买。')
      if(info.is_month == 1){
        this.paymentMonth(info)
      }
      if(info.is_sum == 3){
        this.immediatePayment(info, true)
      }
    },
    // 订阅开续包月
    paymentMonth(info){
      this.payGoodInfo = info
      this.initMonthOrderSign(info)
      this.$refs.aliMonthPayRef.handleOpen(info.price)
    },
    // 支付宝失效刷新回调
    initMonthOrderSign(info){
      initMonthOrderSign({goods_id: info.goods_id}).then((res)=>{
        const { alipay_code, contract_no} = res.data
        this.aliMonthPayCode = alipay_code
        this.handleClearCountDown('aliPayOrderTimer')
        this.handleViewMonthOrderPayStatus(contract_no, 'aliPayOrderTimer')
      })
    },
    // 包月支付回调
    handleMontyPayClose() {
      this.handleClearCountDown('aliPayOrderTimer')
    },
    // 监听连续包月支付结果
    async handleViewMonthOrderPayStatus(contract_no, name) {
      try {
        const { data } = await fetchMonthOrderStatus({contract_no})
        // 判断订单的状态 -1表示订单状态不存在 0表示订单支付成功
        if (data.status == -1) {
          // 添加定时器
          this[name] = setTimeout(() => {
            this.handleViewMonthOrderPayStatus(contract_no, name)
          }, payReloadTime)
          // this.$once('hook:beforeDestroy', () => {
          //   clearTimeout(this[name])
          //   this[name] = null
          // })
          return
        } else if (data.status == 0) {
          this.$refs.aliMonthPayRef.handleBeforeClose()
          this.handleNavMember()
          this.sendEventapi(1)
          this.$store.dispatch('user/getUserInfo')
          this.getPackageList()
        }
        this.handleClearCountDown(name)
      } catch (e) {
        this.handleClearCountDown(name)
      }
    },
    // 立即支付
    immediatePayment( info, order_id) {
      this.payGoodInfo = info
      const { goods_id, has_rel_goods } = info
      //有进阶套餐的
      if (has_rel_goods == 1) {
        this.$refs.ladderPayRef.handleOpen(info, order_id)
      } else {
        //升级会员的
        if (order_id) {
          this.getPayQrCodeUpgrade(goods_id)
        } else {
          this.getPayQrCode(goods_id)
        }
        this.isRetail = false
        this.dialogVisiblemember = true
      }
    },
    //未升级的订单
    getPayQrCode(item) {
      //个人会员
      const goods_id = item
      const params = {
        goods_id: goods_id,
        share_token: window.localStorage.getItem('share_token')
          ? window.localStorage.getItem('share_token')
          : ''
      }
      Promise.all([
        fetchOrderPayByWxPay(params),
        fetchOrderPayByAliPay(params)
      ]).then((res) => {
        this.wxPayOrderNo = res[0].data.order_no
        this.aliPayOrderNo = res[1].data.order_no
        this.wxPayQrCode = res[0].data.pay_url
        this.aliPayQrCode = res[1].data.pay_url
        //this.handleChangePayInfo()
        this.handleViewOrderPayStatus(this.wxPayOrderNo, 'wxPayTimer')
        this.handleViewOrderPayStatus(this.aliPayOrderNo, 'aliPayOrderTimer')
      })
    },
    //升级后的的订单
    getPayQrCodeUpgrade(item) {
      //个人会员
      const goods_id = item
      const order_id = this.order_id
      const params = {
        goods_id: goods_id,
        order_id: order_id || '',
        share_token: window.localStorage.getItem('share_token')
          ? window.localStorage.getItem('share_token')
          : ''
      }
      Promise.all([
        fetchOrderPayByWxPay(params),
        fetchOrderPayByAliPay(params)
      ]).then((res) => {
        this.wxPayOrderNo = res[0].data.order_no
        this.aliPayOrderNo = res[1].data.order_no
        this.wxPayQrCode = res[0].data.pay_url
        this.aliPayQrCode = res[1].data.pay_url
        //this.handleChangePayInfo()
        this.handleViewOrderPayStatus(this.wxPayOrderNo, 'wxPayTimer')
        this.handleViewOrderPayStatus(this.aliPayOrderNo, 'aliPayOrderTimer')
      })
    },
    /**
     * 处理查看订单的支付状态
     * 2021-12-23 10:51:52
     * @author SnowRock
     */
    async handleViewOrderPayStatus(order_no, name) {
      // 进入即刻清除定时器
      try {
        const { data } = await fetchOrderPayStatusByPackage({
          order_no,
          share_token: window.localStorage.getItem('share_token')
            ? window.localStorage.getItem('share_token')
            : ''
        })
        // 判断订单的状态 -1表示订单状态不存在 0表示订单支付成功
        if (data.status == -1) {
          // 添加定时器
          this[name] = setTimeout(() => {
            this.handleViewOrderPayStatus(order_no, name)
          }, payReloadTime)
          // this.$once('hook:beforeDestroy', () => {
          //   clearTimeout(this[name])
          //   this[name] = null
          // })
          return
        } else if (data.status == 0) {
          this.dialogVisiblemember = false
          this.handleClearCountDown('wxPayTimer')
          this.handleClearCountDown('aliPayOrderTimer')
          this.handleNavMember()
          this.sendEventapi(1)
          this.$store.dispatch('user/getUserInfo')
        }
        this.handleClearCountDown(name)
      } catch (e) {
        console.log(e)
        this.handleClearCountDown(name)
      }
    },
    handleNavMember() {
      this.showLetter = true
    },
    closeLetter() {
      this.showLetter = false
    },
    // 清除定时器
    handleClearCountDown(name) {
      const timer = this[name]
      if (timer) {
        clearTimeout(timer)
        this[name] = null
      }
    },
    handlePayClose(){
      this.dialogVisiblemember = false
      this.handleClearCountDown('aliPayOrderTimer')
      this.handleClearCountDown('wxPayTimer')
      this.handleClearCountDown('retailTimer')
    },
    handlePublicSubmit() {
      this.wxPayQrCode = ''
      this.aliPayQrCode = ''
      this.handlePayClose()
      // this.handleNavMember()
      this.sendEventapi(2)

    },
    /**
     * 事件埋点
     */
    sendEventapi(payType) {
      const obj = {
        package_name: this.payGoodInfo.name,
        package_type: this.payGoodInfo.type == 1 ? '个人' : '企业',
        pay_type: payType == 1 ? '在线支付（支付宝/微信）' : '对公转账',
        op_date: formDateTime(),
        m_name: '',
        mid: '',
        reg_date: this.payGoodInfo.create_at,
        user_name: this.userInfo.name,
        user_id: this.userInfo.user_id
      }
      send_eventApi('e_pay', obj)
    },
    async getDetail() {
      this.loading = true
      try {
        const res = await fetchMusicDetail(this.$route.params.id)
        if (res.code === 0) {
          this.musicData = res.data
          this.sceneList = res.data.pricing
          this.breadData[2].breadcrumbName = res.data.name
          this.loading = false
        }
      } catch (error) {
        this.loading = false
        console.log(error)
      }
    },
    // 零售支付
    async handlePay(){
      if(!this.chooseSceneList.length) return this.$message.warning('请选择至少一个授权场景')

      try {
        this.payLoading = true
        const res = await addMusicOrder({
          music_id: this.$route.params.id,
          sk_ids: this.chooseSceneList
        })
        if (res.code === 0) {
          this.payGoodInfo = {...res.data, price: Number(res.data.pay_amount / 100 )}
          this.retailOrderId = res.data.order_id
          this.getMusicPayCode(res.data.order_id)
        }else {
          this.payLoading = false
        }
      } catch (error) {
        this.payLoading = false
        console.log(error)
      }
    },
    // 获取音乐零售订单
    getMusicPayCode(order_id) {
      //个人会员
      Promise.all([
        fetchMusicPayCode({order_id, pay_way:'WECHAT'}),
        fetchMusicPayCode({order_id, pay_way:'ALI'})
      ]).then((res) => {
        console.log(res);
        this.isRetail = true
        this.dialogVisiblemember = true
        this.payLoading = false
        this.retailOrderNO = res[0].data.order_no
        this.wxPayQrCode = res[0].data.pay_url
        this.aliPayQrCode = res[1].data.pay_url
        this.handleViewRetailOrderResult(this.retailOrderNO, 'retailTimer')
      })
    },
    // 零售订单支付结果查询
    async handleViewRetailOrderResult(order_no, name) {
      // 进入即刻清除定时器
      try {
        const { data } = await fetchPayCodeResult({
          order_no,
          share_token: window.localStorage.getItem('share_token')
            ? window.localStorage.getItem('share_token')
            : ''
        })
        // 判断订单的状态 -1表示订单状态不存在 0表示订单支付成功
        if (data.status == -1) {
          // 添加定时器
          this[name] = setTimeout(() => {
            this.handleViewRetailOrderResult(order_no, name)
          }, payReloadTime)
          return
        } else if (data.status == 0) {
          this.dialogVisiblemember = false
          this.wxPayQrCode = ''
          this.aliPayQrCode = ''
          this.handleClearCountDown('retailTimer')
          this.$router.push(`/music/order-result/${this.retailOrderId}`)
        }
        this.handleClearCountDown(name)
      } catch (e) {
        console.log(e)
        this.handleClearCountDown(name)
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.tooltip-c{
  width: 360px;
}
.music-detail-container {
  min-height: 100vh;
  min-width: 1000px;
  padding: 90px 0;
  .crumb-wapper{
    width: 1200px;
    margin: 0 auto;
    margin-bottom: 20px;
  }
  .content {
    width: auto;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    width: 1200px;
    .l-wrap {
      width: 890px;
      .music-info {
        display: flex;
        justify-content: space-between;
        width: 100%;
        height: 180px;
        padding: 0 29px 0 5px;
        margin-bottom: 20px;
        .flex {
          display: flex
        }
        .header-img{
          width: 180px;
          height: 180px;
          border-radius: 8px;
          position: relative;
          .img {
            width: 100%;
            height: 100%;
            border-radius: 8px;
            background: rgba(0, 0, 0, 0.2);
          }
          .audio-status {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border-radius: 8px;
            background: rgba(0, 0, 0, .2);
            display: flex;
            align-items: center;
            justify-content: center;
            img{
              width: 40px;
              height: 40px;
            }
          }
        }
        img {
          @include size(180px, 180px);
          border-radius: 8px;
        }
        .info {
          display: flex;
          flex-direction: column;
          margin-left: 24px;
        }
        h3 {
          color: rgba(0,0,0,0.85);
          font-weight: 500;
          font-size: 20px;
        }
        h4 {
          margin: 14px 0 28px;
          font-size: 14px;
          color: rgba(0,0,0,0.5);
        }
        span {
          cursor: pointer;
          color: #0061F3;
          font-size: 14px;
          text-decoration:underline;
        }
        .operation {
          margin-top: 20px;
          display: flex;
          div{
            cursor: pointer;
            @include size(54px, 54px);
            @include center-middle-flex;
            background: #F5F6FA;
            border-radius: 8px;
            img{
              @include size(24px, 24px);
            }
            &:nth-child(1){
              margin-right: 12px;
            }
          }
        }

      }
      .package-info{
        @include size(890px, 330px);
        padding: 0 25px;
        position: relative;
        .package-bg {
          @include size(100%, 100%);
          position: absolute;
          left: 0;
          top: 0;
          z-index: -1;
          img{
            @include size(100%, 100%);
          }
        }
        .goods-tab{
          width: 100%;
          height: 90px;
          display: flex;
          & > div{
            cursor: pointer;
            width: 50%;
          }
          .person-tab{
            padding: 35px 0 0 35px;
            color: #fff;
            strong {
              font-size: 20px;
              letter-spacing: 2px;
            }
            span {
              font-size: 14px;
              margin-left: 12px;
            }
          }
          .company-tab {
            padding: 35px 0 0 60px;
            color: #FFFFFF;
            strong {
              font-size: 20px;
              letter-spacing: 2px;
            }
            p{
              display: inline-block;
              margin: 0;
              margin-left: 10px;
              border-radius: 2px;
              width: 68px;
              height: 20px;
              line-height: 20px;
              text-align: center;
              font-size: 12px;
              font-style: normal;
              color: #CE8414;
              background: linear-gradient(135deg, #FFE99D 0%, #FFD27C 100%);
              transform: skew(-8deg);
            }
            span{
              font-size: 12px;
              margin-left: 12px;
            }
          }
          .person-active, .company-active {
            padding-top: 30px;

          }
          .person-active{
            color: #333333;
            letter-spacing: 2px;
            font-size: 20px;
            span{
              color: #999;
            }
            &::after {
              content: url("~@/assets/image/set_tab_arrow.svg");

            }
          }
          .company-active {
            color: #333;
            span{
              color: #999;
              font-size: 14px;
            }
          }
        }
        .goods-wrap {
          display: flex;
          justify-content: space-between;
          .person-goods-item, .company-goods-item{
            cursor: pointer;
            border-radius: 12px;
            @include size(200px, 216px);
            display: flex;
            flex-direction: column;
            align-items: center;
            padding-top: 18px;
            .title {
              color: #333333;
              font-size: 14px;
              font-weight: bold;
            }
            .price {
              font-family: alipuhuir;
              font-size: 40px;
              i{
                font-style: normal;
                font-weight: bolder;
                font-size: 24px;
              }
            }
            .desc{
              color: #999999;
              font-size: 12px;
              width: 160px;
              margin: 0 auto;
              div{
                @include center-middle-flex($justify: space-between);
                margin-bottom: 6px;
              }
            }
            .pay {
              width: 160px;
              height: 28px;
              text-align: center;
              line-height: 28px;
              border-radius: 14px;
              color: #fff;
              background: linear-gradient(135deg, #FC4243 0%, #FE7273 100%);
              font-size: 12px;
            }
          }
          .person-goods-item{
            border: 1px solid #E2E2E2;
            .desc {
              margin-bottom: 18px;
            }
            .price {
              color: #FF2F0F;
              background: linear-gradient(135deg, #FC4243 0%, #FE7273 100%);
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
              // display: block;
            }
          }
          .person-goods-item.choose {
            background: linear-gradient(135deg, #FFFFFF 0%, #FFF8F9 100%);
            border-radius: 20px;
            border: 3px solid rgba(254, 113, 114, .5);
            .pay {
              background: linear-gradient(135deg, #FE7273 0%, #FC4243 100%);
            }
          }

          .company-goods-item {
            border: 1px solid #E2E2E2;
            .price {
              color: #FF2F0F;
              background: linear-gradient(135deg, #FC8742 0%, #FEC372 100%);
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
            }
            .desc {
              margin-bottom: 6px;
            }
            .pay {
              background: linear-gradient(135deg, #FEC372 0%, #FC8742 100%);
            }
          }
          .company-goods-item.choose {
            background: linear-gradient(135deg, #FFFFFF 0%, #FFFCF3 100%);
            border-radius: 20px;
            border: 3px solid #FEEBC7;
            .pay {
              background: linear-gradient(135deg, #FC8742 0%, #FEC372 100%);
            }
          }
        }
      }

    }
    .scene-info {
      width: 290px;
      margin-top: 20px;
      .scene-wrap{
        @include size(290px, 450px);
        background: #FFFFFF;
        box-shadow: inset 0px 2px 7px 0px rgba(0,0,0,0.1);
        border-radius: 20px;
        position: relative;
        padding-top: 8px;
        .img-icon{
          @include size(157px, 42px);
          position: absolute;
          left: 0;
          top: -16px;
          img{
            @include size(100%, 100%);
            position: absolute;
            left: 0;
            top: 0;
          }
          span {
            font-size: 12px;
            font-weight: 500;
            color: #FFFFFF;
            line-height: 12px;
            position: absolute;
            left: 31px;
            top: 10px;
            z-index: 1;
          }
        }
        .title {
          height: 52px;
          line-height: 52px;
          font-size: 16px;
          font-weight: 600;
          color: #333333;
          text-align: center;
        }
        .tip{
          margin: 0 auto;
          font-size: 12px;
          color: #FF8E14;
          display: flex;
          justify-content: center;
          i{
            margin-right: 8px;
            font-size: 14px;
          }
          p{
            width: 230px;
            margin: 0;
          }
        }
      }
      .scene-items {
        margin: 0 auto 12px auto;
        cursor: pointer;
        @include size(258px, 56px);
        border-radius: 12px;
        border: 1px solid rgba(0,0,0,0.05);
        color: #333333;
        padding: 0 20px;
        @include center-middle-flex($justify: space-between);
        position: relative;
        .name {
          div {
            position: relative;
            display: flex;
            align-items: center;
            &::before{
              content: '';
              display: inline-block;
              width: 14px;
              height: 14px;
              border-radius: 50%;
              border: 1px solid #E52A0D;
              margin-right: 10px;
            }
            span {
              font-size: 14px;
              color: #666666;
            }
            .qus{
              margin-left: 6px;
              @include size(14px, 14px);
            }
          }
          .active {
            &::before {
              content: url("~@/assets/image/music/check.svg");
              border: none;
            }
          }
        }

        .price {
          font-size: 20px;
          font-family: alipuhuir;
          font-weight: bold;
          color: #666666;

        }
      }
      .scene-active {
        border: 1px solid #E52A0D;
        .price {
          color: #333;
        }
      }
    }
    .pay-info {
      margin-top: 14px;
      position: relative;
      @include size(290px, 40px);
      border-radius: 19px;
      overflow: hidden;
      .price {
        @include size(100%, 100%);
        background: #FAFAFA;
        padding-left: 30px;
        line-height: 40px;
        border: 1px solid #EDEDED;
        border-radius: 19px;
        font-size: 12px;
        color: #999999;
        span {
          color: #999999;
          font-size: 12px;
          font-weight: 400;
        }
      }
      .retail-pay {
        cursor: pointer;
        position: absolute;
        right: 0;
        top: 0;
        @include size(129px, 40px);
        line-height: 40px;
        text-align: center;
        background: url("~@/assets/image/music/pay-bg.png") no-repeat;
        background-size: cover;
        font-size: 16px;
        font-weight: 600;
        color: #FFFFFF;
        border: none;
        padding: 0;
      }
    }
    .pay-active {
      .price {
        color: #666666;
        span {
          color: #E52A0D;
          font-size: 16px;
          font-weight: 500;
        }
      }
      .retail-pay {
        background: url("~@/assets/image/music/pay-bg-active.png") no-repeat;
        background-size: cover;
      }
    }
  }
  .recommend {
    margin: 60px auto;
    width: 1200px;
    .title {
      font-size: 36px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      line-height: 50px;
      margin-bottom: 36px;
    }
  }
}
</style>
